import Image from '@/components/base/image'
import { IPopupProps } from './types'
import Video from '@/components/base/video'
import Icon from '@/components/base/icon'
import { useEffect, useLayoutEffect, useState } from 'react'
import useDeviceType from '@/hook/useDeviceType'
import { IUseDeviceType } from '@/hook/types'

const Popup: React.FC<IPopupProps> = ({
  video,
  image,
  mobileImage,
  mobileVideo,
}) => {
  const deviceType = useDeviceType()
  const [showPopup, setShowPopup] = useState(false)
  const daysInterval = 3

  const renderAsset = (deviceType: IUseDeviceType) => {
    if (deviceType === 'desktop' || deviceType === 'laptop') {
      if (video?.src) {
        return <Video {...video} autoPlay muted />
      } else {
        return <Image {...image} alt="popup" />
      }
    } else {
      if (mobileVideo?.src) {
        return <Video {...mobileVideo} autoPlay muted />
      } else {
        return <Image {...mobileImage} alt="popup" />
      }
    }
  }

  const isAfterNDays = (lastDate: Date, days: number) => {
    const today = new Date()
    const timeDifference = today.getTime() - lastDate.getTime()
    const daysDifference = timeDifference / (1000 * 3600 * 24)
    return daysDifference >= days
  }

  useLayoutEffect(() => {
    if (showPopup) {
      document.body.classList.add('disabled-scroll')
    }

    return () => {
      document.body.classList.remove('disabled-scroll')
    }
  }, [showPopup])

  useEffect(() => {
    // const lastShownDate = localStorage.getItem('popupLastShownDate')
    // if (lastShownDate && !isAfterNDays(new Date(lastShownDate), daysInterval)) {
    //   setShowPopup(false)
    // } else {
    //   setShowPopup(true)
    // }

    setShowPopup(true)
  }, [])

  const handleClick = () => {
    const today = new Date()

    setShowPopup(false)
    localStorage.setItem('popupLastShownDate', today.toISOString())
  }

  if (
    (image?.src || video?.src) &&
    showPopup &&
    (deviceType === 'desktop' ||
      deviceType === 'laptop' ||
      deviceType === 'tablet')
  ) {
    return (
      <div className="popup">
        <div className="popup-media">
          <button className="popup-media-cancel-button" onClick={handleClick}>
            <Icon name="close" />
          </button>

          <div className="popup-media-container">{renderAsset(deviceType)}</div>
        </div>
      </div>
    )
  }

  if (
    (mobileImage?.src || mobileVideo?.src) &&
    showPopup &&
    deviceType === 'mobile'
  ) {
    return (
      <div className="popup">
        <div className="popup-media">
          <button className="popup-media-cancel-button" onClick={handleClick}>
            <Icon name="close" />
          </button>

          <div className="popup-media-container">{renderAsset(deviceType)}</div>
        </div>
      </div>
    )
  }

  return null
}

export default Popup
