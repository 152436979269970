import { useState, useEffect } from 'react'
import { IUseDeviceType } from './types'

const useDeviceType = (): IUseDeviceType => {
  const getDeviceType = (width: number): IUseDeviceType => {
    if (width < 768) {
      return 'mobile'
    } else if (width >= 768 && width < 1024) {
      return 'tablet'
    } else if (width >= 1024 && width < 1440) {
      return 'laptop'
    } else {
      return 'desktop'
    }
  }

  const [deviceType, setDeviceType] = useState<IUseDeviceType>('unknown')

  useEffect(() => {
    const updateDeviceType = () => {
      setDeviceType(getDeviceType(window.innerWidth))
    }

    updateDeviceType()
    window.addEventListener('resize', updateDeviceType)

    return () => window.removeEventListener('resize', updateDeviceType)
  }, [])

  return deviceType
}

export const useMobileRotateDeviceType = (limit: number) => {
  const checkDeviceType = (width: number) => {
    return width < limit
  }

  const [checkRotate, setCheckRotate] = useState(false)

  useEffect(() => {
    const updateDeviceType = () => {
      setCheckRotate(checkDeviceType(window.innerWidth))
    }

    updateDeviceType()
    window.addEventListener('resize', updateDeviceType)

    return () => window.removeEventListener('resize', updateDeviceType)
  }, [])

  return checkRotate
}

export default useDeviceType
